
import { defineComponent } from 'vue'
import ArrowBack from '@/assets/svg/arrow-back.svg?inline'
import { Form, Field, ErrorMessage } from 'vee-validate'
import AlertBlock from '@/components/molecules/AlertBlock.vue'
// import Select from '@/components/atoms/CustomSelect.vue'
import PhotoMock from '@/assets/svg/photo-zaglushka.svg?inline'
import authHeader from '@/services/auth-header'

export default defineComponent({
  name: 'CreateInstrctor',
  data () {
    return {
      dataSwimming: ['Swimming'],
      dataSchool: ['SRA'],
      imagePreview: null,
      isPreview: false,
      full_name: '',
      emailInstructor: '',
      phone_number: null,
      password: '',
      password_confirmation: '',
      location: '',
      errorInstructor: null,
      schoolsSchools: null,
      file: null,
      instructorId: null,
      instructorData: {},
      editTeg: false,
      serverError: null
    }
  },
  components: {
    ArrowBack,
    Form,
    Field,
    // Select,
    // ErrorMessage,
    AlertBlock,
    PhotoMock
  },
  mounted () {
    this.instructorId = this.$route.query.instructor
    if (this.$route.query.edit !== undefined) {
      this.editTeg = this.$route.query.edit
    }
    if (this.instructorId !== undefined) {
      this.getInstructor(this.instructorId)
    }
    this.getSchools()
  },
  methods: {
    getInstructor (id) {
      this.$store.dispatch('instructors/getInstructor', id).then(
        (res) => {
          this.instructorData = res.data
          this.full_name = this.instructorData.full_name
          this.emailInstructor = this.instructorData.email
          this.location = this.instructorData.location
          this.phone_number = this.instructorData.phone.value
          this.password = this.instructorData.password
          this.password_confirmation = this.instructorData.password_confirmation
        },
        (error) => {
          this.errorInstructor = error.response.data.errors
        }
      )
    },
    getSchools (id) {
      id = 1
      this.$store.dispatch('auth/getSchools', id).then(
        (res) => {
          this.schoolsData = res.data
        },
        (error) => {
          this.schoolsSchools = error.response.data.errors
        }
      )
    },
    createInstructor () {
      const API_URL = process.env.VUE_APP_URL
      const formData = new FormData()
      const sportIds = ['1']
      const schoolIds = ['1']
      formData.append('full_name', this.full_name)
      formData.append('email', this.emailInstructor)
      formData.append('phone', this.phone_number)
      formData.append('location', this.location)
      if (this.password !== undefined) {
        formData.append('password', this.password)
      }
      if (this.password_confirmation !== undefined) {
        formData.append('password_confirmation', this.password_confirmation)
      }
      sportIds.forEach(id => {
        formData.append('sport_ids[]', id)
      })
      schoolIds.forEach(id => {
        formData.append('school_ids[]', id)
      })
      if (this.file !== null) {
        formData.append('image', this.file)
      }
      if (this.editTeg === false) {
        this.axios.post(API_URL + 'admin/instructors', formData, { headers: authHeader() }).then(() => {
          this.$router.push('/admin/instructors')
        },
        (error) => {
          this.errorInstructor = error.response.data.errors
          this.serverError = error.response.data
        })
      } else {
        this.axios.post(API_URL + 'admin/instructors/' + this.instructorId + '/update', formData, { headers: authHeader() }).then(() => {
          this.$router.push('/admin/instructors')
        },
        (error) => {
          this.errorInstructor = error.response.data.errors
        })
      }
    },
    handleOptionSelected (selectedOption) {
      console.log(selectedOption)
    },
    handleFileUpload () {
      this.file = this.$refs.file.files[0]
      const formData = new FormData()
      formData.append('thumbnail', this.file)
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        function () {
          this.imagePreview = reader.result
          this.isPreview = true
        }.bind(this),
        false
      )
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file)
        }
      }
    }
  }
})
